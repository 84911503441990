import React from "react";
import { Layout, Menu } from "antd";
import {
  argue,
  homeIcon,
  logOutIcon,
  logo,
  privacyIcon,
  termsConIcon,
  userIcon,
} from "../assets";
import "./layout.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accessToken, refreshToken, userData } from "../redux/userDataSlice";
import { callApi } from "../api/apiCaller";
import { routes } from "../api/routes";
import { useState } from "react";
import Loader from "../components/loader/loader";
import { GreenNotify, RedNotify } from "../helper/helper";
import Dashboard from "../pages/dashboard/dashboard";
import Home from "../pages/home/home";
import Users from "../pages/userlist/Users";
import History from "../pages/getHistory/History";
import Term from "../pages/termandCondition/term";
import Privacy from "../pages/privacy/privacy";
import AboutUS from "../pages/aboutUs/aboutUs";
import {
  aboutIcon,
  dispute,
  disputeIcon,
  disputeIconF,
  offersIcon,
  orderHistory,
  privcyIcon,
  requestIcon,
  termIcon,
} from "./icon";
import Disputes from "../pages/disputes/disputes";
import Offers from "../pages/offers/offers";
import Request from "../pages/request/request";
import OrderHistory from "../pages/orderHistory/orderHistory";
import OfferDetails from "../pages/offerDetails/offerDetails";

const { Content, Sider } = Layout;
const LayoutDashboard = () => {
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    let getRes = (res) => {
      if (res.status === 200) {
        GreenNotify(res.message);
        dispatch(userData(null));
        dispatch(accessToken(""));
        dispatch(refreshToken(""));
      } else {
        RedNotify(res.message);
      }
    };

    let body = {
      device: {
        id: localStorage.getItem("deviceId"),
        deviceToken: "xyz",
      },
    };

    callApi("POST", routes.logOut, body, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Loader loading={isloading} />
      <Sider
        style={{ background: "linear-gradient(to right, #7254B2, #C64884)" }}
        width={280}
      >
        <div
          onClick={() => navigate("/")}
          style={{
            paddingTop: "4rem",
            textAlign: "center",
            color: "white",
            fontSize: "2.3rem",
            cursor: "pointer",
          }}
        >
          <img src={logo} className="main-logo" />
        </div>
        <Menu
          style={{
            marginTop: "7rem",
            fontFamily: "sans-serif",
            fontSize: "1.8rem",
            fontWeight: "700",
            color: "#fff",
          }}
          className="menu-background"
          inlineCollapsed={true}
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
        >
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/")}
            icon={
              <img className="side-bar-icon" src={homeIcon} alt="menu-icon" />
            }
            key="92"
          >
            Home
          </Menu.Item>

          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/users")}
            icon={<img className="icon" src={userIcon} alt="menu-icon" />}
            key="95"
          >
            Users
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/request")}
            icon={offersIcon}
            key="45"
          >
            Request
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/offers")}
            icon={requestIcon}
            key="46"
          >
            Offers
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/order-history")}
            icon={orderHistory}
            key="27"
          >
            Order History
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/dispute")}
            icon={
              <img
                className="log-out"
                style={{ width: "3rem", height: "3rem" }}
                src={argue}
                alt=""
              />
            }
            key="25"
          >
            Dispute orders
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/term-and-condition")}
            icon={termIcon}
            key="96"
          >
            Term and Condition
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/privacy-and-policy")}
            icon={privcyIcon}
            key="97"
          >
            Privacy and Policy
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/about-us")}
            icon={aboutIcon}
            key="98"
          >
            About Us
          </Menu.Item>
          <Menu.Item
            style={{ marginTop: "5rem" }}
            icon={<img className="log-out" src={logOutIcon} alt="" />}
            onClick={logOut}
            key="89"
          >
            Log Out
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content
          style={{
            background: "#fff",
            paddingTop: "2rem",
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/users" element={<Users />}></Route>
            <Route path="/user-history" element={<History />}></Route>
            <Route path="/term-and-condition" element={<Term />}></Route>
            <Route path="/privacy-and-policy" element={<Privacy />}></Route>
            <Route path="/about-us" element={<AboutUS />}></Route>
            <Route path="/dispute" element={<Disputes />}></Route>
            <Route path="/offers" element={<Offers />}></Route>
            <Route path="/request" element={<Request />}></Route>
            <Route path="/order-history" element={<OrderHistory />}></Route>
            <Route
              path="/offer-request-details"
              element={<OfferDetails />}
            ></Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutDashboard;

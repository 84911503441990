import React, { useState, useEffect } from "react";
import "./users.css";
import { Breadcrumb, Table, Image, Checkbox } from "antd";
import { crossIcon, history, homeIcon, redTrash, trueIcon } from "../../assets";
import { callApi } from "../../api/apiCaller";
import { routes } from "../../api/routes";
import Loader from "../../components/loader/loader";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [updateApi, setUpdateApi] = useState(false);
  const [results, setResults] = useState(0);
  const navigate = useNavigate();
  const getAllUser = () => {
    let getRes = (res) => {
      console.log("res of user list", res);
      setUsers(res?.data);
      setResults(res?.results);
    };

    callApi("GET", routes.getAllUser, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  const blockUser = (id) => {
    setUpdateApi(false);
    let getRes = (res) => {
      setUpdateApi(true);
    };

    callApi(
      "GET",
      `${routes.blockUser}/${id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getAllUser();
  }, [updateApi]);
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      className: "role-name-column-header",
    },

    {
      title: "Email",
      dataIndex: "email",
      align: "center",
      className: "type-name-column-header",
      width: 400,
    },

    {
      title: "Profile Picture",
      dataIndex: "profilePicture",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Account Created",
      dataIndex: "date",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Verified",
      dataIndex: "verified",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Order History",
      dataIndex: "history",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Block",
      dataIndex: "delete",
      align: "center",
      className: "action-column-header",
      width: 200,
    },
  ];

  const data = users
    ?.filter((item) => item.role !== "admin")
    ?.map((item, index) => {
      return {
        key: index,
        firstName: item?.firstName,
        email: item?.email,
        date: moment(item?.createdAt).format("MM-DD-YYYY"),
        profilePicture: (
          <div className="product-list-image">
            <Image width={50} src={item?.image} alt="profile-image" />
          </div>
        ),
        verified: (
          <div className="server-roles-trash-btn">
            <img src={item?.verified ? trueIcon : crossIcon} alt="" />
          </div>
        ),
        history: (
          <div
            onClick={() =>
              navigate("/user-history", {
                state: { id: item?.id, name: item?.firstName },
              })
            }
            className="server-roles-trash-btn"
          >
            <img src={history} alt="" />
          </div>
        ),

        delete: (
          <Checkbox
            className="custom-checkbox"
            checked={item?.isBlocked}
            onChange={() => blockUser(item?.id)}
          >
            {item?.isBlocked ? "Block" : "Not Block"}
          </Checkbox>
        ),
      };
    });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Users </Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Users ({results - 1})</h1>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
        ></Table>
      </div>
    </div>
  );
};

export default Users;

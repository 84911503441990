import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Breadcrumb } from "antd";
import { Flex, Progress } from "antd";
import { ProgressProps } from "antd";
import {
  dashboardUser,
  disputeIcon,
  homeIcon,
  offers,
  productIcon1,
  request,
  serviceIcon1,
  users,
} from "../../assets";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import moment from "moment/moment";
import { callApi } from "../../api/apiCaller";
import { routes } from "../../api/routes";
import Loader from "../../components/loader/loader";
const Dashboard = () => {
  // const [value, setValue] = useState(new Date());
  const [isloading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({});
  const [totalOffers, setTotalOffers] = useState(0);
  const [completeOffers, setCompleteOffers] = useState(0);
  const [totalRequest, settTotalRequest] = useState(0);
  const [completeRequest, setCompleteRequest] = useState(0);
  const stateArr = [
    {
      title: "Total Users",
      count: stats?.numberOfUsers,
      icon: users,
    },
    {
      title: "Total Offers",
      count: stats?.numberOfOffers,
      icon: offers,
    },
    {
      title: "Total Request",
      count: stats?.numberOfRequests,
      icon: request,
    },
    {
      title: "Total dispute",
      count: stats?.numberOfDispute,
      icon: disputeIcon,
    },
  ];

  useEffect(() => {
    getStats();
  }, []);

  const getStats = () => {
    let getRes = (res) => {
      console.log("res of getStats", res);
      setStats(res.data);
      setTotalOffers(res.data.numberOfOffers);
      setCompleteOffers(res.data?.numberOfCompletedOffers);
      settTotalRequest(res?.data?.numberOfRequests);
      setCompleteRequest(res?.data?.numberOfCompletedRequest);
    };

    callApi("GET", routes.userStats, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  useEffect(() => {
    getStats();
  }, []);

  const twoColors = {
    "0%": "#7254B2",
    "100%": "#C64884",
  };

  const completeOfferPer = (completeOffers / totalOffers) * 100;
  const completeRequestPer = (completeRequest / totalRequest) * 100;

  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="icon-dashboard">
          <img
            style={{
              width: "1.7rem",
              height: "1.7rem",
            }}
            src={homeIcon}
            alt="home-icon"
          />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <div className="dashboard-main-container">
        <div className="dashboard-state-container">
          {stateArr.map((item) => (
            <div className="dashboard-state-count-container">
              <div className="dashboard-state-icon">
                <img src={item.icon} alt="icon" />
              </div>
              <div className="dashboard-state">
                <h2>{item.title}</h2>
                <p>{item.count}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="dashboard-pie-chart-container">
          <Clock
            size={120}

            // value={value}
          />
          <p>
            Current time:{" "}
            <span style={{ color: "red", fontWeight: "700" }}>
              {moment(new Date()).format("DD, MMM, YYYY , HH:mm A")}
            </span>
          </p>
        </div>
      </div>
      <div className="progress-tracking-container">
        <div className="progress-tracking-sub-container">
          <div className="progress-track">
            <div className="progress-heading">
              <h3>Total Offers : {totalOffers}</h3>
            </div>
            <Progress
              type="circle"
              size={200}
              percent={parseInt(completeOfferPer)}
              strokeColor={twoColors}
              format={(percent) => (
                <span
                  style={{ fontSize: "1.8rem", fontWeight: "bold" }}
                >{`  ${percent}% Complete`}</span>
              )}
            />
          </div>
          <div className="progress-track">
            <div className="progress-heading">
              <h3>Total Requests : {totalRequest}</h3>
            </div>
            <Progress
              type="circle"
              size={200}
              percent={parseInt(completeRequestPer)}
              strokeColor={twoColors}
              format={(percent) => (
                <span
                  style={{ fontSize: "1.8rem", fontWeight: "bold" }}
                >{`  ${percent}% Complete`}</span>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

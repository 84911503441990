import React, { useState, useEffect } from "react";
import { Breadcrumb, Table, Image, Rate } from "antd";
import {
  crossIcon,
  history,
  homeIcon,
  orderIcon,
  redTrash,
  trueIcon,
} from "../../assets";
import { callApi } from "../../api/apiCaller";
import { routes } from "../../api/routes";
import Loader from "../../components/loader/loader";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./offerDetails.css";

const OfferDetails = () => {
  const [orderDetail, setOrderDetails] = useState({ orderNumber: 0 });
  const location = useLocation();
  const type = location?.state.type;
  const id = location?.state?.id;
  //console.log("location", type);

  const [isloading, setIsLoading] = useState(false);
  const [updateApi, setUpdateApi] = useState(false);
  const navigate = useNavigate();
  const getAllUser = () => {
    let getRes = (res) => {
      console.log("res", res);
      //setUsers(res?.data);
      setOrderDetails(res?.data);
    };

    callApi(
      "GET",
      type == "Offer"
        ? `${routes.singleOfferDetail}/${id}`
        : `${routes.singleRequestDetail}/${id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  const deleteUser = (id) => {
    setUpdateApi(false);
    let getRes = (res) => {
      console.log("res of user list", res);
      setUpdateApi(true);
    };

    callApi(
      "DELETE",
      `${routes.DeleteUser}/${id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getAllUser();
  }, [updateApi]);

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    console.log("jnj", orderDetail?.friendReview?.rating),
    (
      <div className="admin-products-main-container">
        <Loader loading={isloading} />
        <Breadcrumb separator=">" className="bread-crumb">
          <div className="configure-server-home-icon">
            <img src={homeIcon} alt="home-icon" />
          </div>
          <Breadcrumb.Item>Home</Breadcrumb.Item>

          <Breadcrumb.Item>
            {type == "Offer" ? "Offer" : "Request"}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="configure-server-roles-main-heading-container">
          <h1> {type == "Offer" ? "Offer" : "Request"}</h1>
        </div>
        <div className="order-status-container">
          <img src={orderIcon} alt="icon" />
          <div className="order-number-container">
            <h3>Order no. {orderDetail.orderNumber}</h3>
            <div className="order-status-container-sub">
              <h4>Complete</h4>
            </div>
          </div>
        </div>
        <div className="compete-date">
          <h1>Completion date</h1>
          <h2>{`${moment(orderDetail?.history?.createdAt).fromNow()}`}</h2>
        </div>
        {orderDetail?.history?.productName && (
          <div className="compete-date">
            <h1>Product Name</h1>
            <h2>{orderDetail?.history?.productName}</h2>
          </div>
        )}
        <div className="compete-date">
          <h1>Location</h1>
          <h2>{orderDetail?.history?.location?.address}</h2>
        </div>
        <div className="compete-date">
          <h1>Note</h1>
          <h2>{orderDetail?.history?.note}</h2>
        </div>
        <div className="review-container">
          <h1>Friends Review</h1>
          <div className="review-box-container">
            <div className="user-details-container">
              <div className="user-image">
                <img
                  src={orderDetail?.friendReview?.reviewBy?.image}
                  alt="friend-image.jpg"
                />
              </div>
              <div style={{ marginLeft: "1rem" }}>
                <h3>Friends User</h3>
                <h5 style={{ marginTop: "1rem" }}>
                  {moment(orderDetail?.friendReview?.createdAt).format(
                    "DD - MM - YYYY"
                  )}
                </h5>
              </div>
            </div>
            <div>
              {orderDetail?.friendReview?.rating !== undefined && (
                <Rate
                  defaultValue={orderDetail?.friendReview?.rating}
                  disabled
                />
              )}
            </div>
          </div>
        </div>
        <div className="review-container">
          <h1>Neighbor Review </h1>
          <div className="review-box-container">
            <div className="user-details-container">
              <div className="user-image">
                <img
                  src={orderDetail?.friendReview?.reviewBy?.image}
                  alt="friend-image.jpg"
                />
              </div>
              <div style={{ marginLeft: "1rem" }}>
                <h3>{orderDetail?.neighbourReviews?.reviewBy?.role} </h3>
                <h5 style={{ marginTop: "1rem" }}>
                  {moment(orderDetail?.neighbourReviews?.createdAt).format(
                    "DD - MM - YYYY"
                  )}
                </h5>
              </div>
            </div>
            <div>
              {orderDetail?.neighbourReviews?.rating !== undefined && (
                <Rate
                  defaultValue={orderDetail?.neighbourReviews?.rating}
                  disabled
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "2rem" }}></div>
      </div>
    )
  );
};

export default OfferDetails;

export const BASE_URL = "https://api.udenden.com/api/v1";

export const routes = {
  // -----AUTH------//
  signUp: BASE_URL + "/user/signup",
  signIn: BASE_URL + "/users/testLogin",
  getHistory: BASE_URL + "/offer/user",
  logOut: BASE_URL + "/users/logout",
  getAllUser: BASE_URL + "/users",
  createTern: BASE_URL + "/terms/create",
  getTerm: BASE_URL + "/terms",
  updateTerm: BASE_URL + "/terms",
  deleteTerm: BASE_URL + "/terms",
  getPrivacy: BASE_URL + "/privacy",
  getAboutUs: BASE_URL + "/aboutUs",
  createAboutUs: BASE_URL + "/aboutUs/create",
  userStats: BASE_URL + "/users/stats",
  getDispute: BASE_URL + "/dispute",
  blockUser: BASE_URL + "/users/block-user",
  getAllOffers: BASE_URL + "/offer",
  getAllRequest: BASE_URL + "/request",
  orderHistory: BASE_URL + "/users/getRequestAndOfferHistoryForAdmin",
  singleOfferDetail: BASE_URL + "/offer/historyForAdmin",
  singleRequestDetail: BASE_URL + "/request/historyForAdmin",

  // -----------Products--------------//

  // -------------Services-------------//

  // -------------Services-------------//
};

import React, { useState, useEffect } from "react";

import { Breadcrumb, Table, Image } from "antd";
import { crossIcon, history, homeIcon, redTrash, trueIcon } from "../../assets";
import { callApi } from "../../api/apiCaller";
import { routes } from "../../api/routes";
import Loader from "../../components/loader/loader";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const Disputes = () => {
  const [users, setUsers] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [updateApi, setUpdateApi] = useState(false);
  const navigate = useNavigate();
  const getAllUser = () => {
    let getRes = (res) => {
      setUsers(res?.data);
      console.log("get dispute", res);
    };

    callApi("GET", routes.getDispute, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  const deleteUser = (id) => {
    setUpdateApi(false);
    let getRes = (res) => {
      setUpdateApi(true);
    };

    callApi(
      "DELETE",
      `${routes.getDispute}/${id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getAllUser();
  }, [updateApi]);
  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
      className: "type-name-column-header",
      width: 400,
    },

    {
      title: "Subject",
      dataIndex: "subject",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      className: "action-column-header",
    },

    {
      title: "Delete",
      dataIndex: "delete",
      align: "center",
      className: "action-column-header",
    },
  ];

  const data = users?.map((item, index) => {
    return {
      key: index,
      email: item?.email,
      subject: item?.subject,
      description: item?.description,
      date: moment(item?.createdAt).format("MM-DD-YYYY"),
      profilePicture: (
        <div className="product-list-image">
          <Image width={50} src={item?.image} alt="profile-image" />
        </div>
      ),
      verified: (
        <div className="server-roles-trash-btn">
          <img src={item?.verified ? trueIcon : crossIcon} alt="" />
        </div>
      ),
      history: (
        <div
          onClick={() =>
            navigate("/user-history", {
              state: { id: item?.id, name: item?.firstName },
            })
          }
          className="server-roles-trash-btn"
        >
          <img src={history} alt="" />
        </div>
      ),
      subscription: (
        <p>
          {item?.subscriptionPlan === "free"
            ? "Free"
            : item.subscriptionPlan === "monthly"
            ? "Monthly"
            : "Yearly"}
        </p>
      ),
      delete: (
        <div style={{ cursor: "pointer" }} onClick={() => deleteUser(item?.id)}>
          <img src={redTrash} alt="" />
        </div>
      ),
    };
  });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Dispute</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Dispute Orders</h1>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
        ></Table>
      </div>
    </div>
  );
};

export default Disputes;

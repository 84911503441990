import React, { useState, useEffect } from "react";
import { Breadcrumb, Table, Image } from "antd";
import { crossIcon, history, homeIcon, redTrash, trueIcon } from "../../assets";
import { callApi } from "../../api/apiCaller";
import { routes } from "../../api/routes";
import Loader from "../../components/loader/loader";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Request = () => {
  const [users, setUsers] = useState([]);
  const location = useLocation();

  const [isloading, setIsLoading] = useState(false);
  const [updateApi, setUpdateApi] = useState(false);
  const navigate = useNavigate();
  const getAllUser = () => {
    let getRes = (res) => {
      console.log("res of user list", res);
      setUsers(res?.data);
    };

    callApi(
      "GET",
      `${routes.getAllRequest}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  const deleteUser = (id) => {
    setUpdateApi(false);
    let getRes = (res) => {
      console.log("res of user list", res);
      setUpdateApi(true);
    };

    callApi(
      "DELETE",
      `${routes.DeleteUser}/${id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getAllUser();
  }, [updateApi]);
  const columns = [
    {
      title: "Created By",
      dataIndex: "createdBy",
      className: "role-name-column-header",
    },
    {
      title: "Accepted By",
      dataIndex: "firstName",
      className: "role-name-column-header",
    },
    {
      title: "Product Name",
      dataIndex: "product",
      className: "role-name-column-header",
    },

    {
      title: "Created Time",
      dataIndex: "email",
      align: "center",
      className: "type-name-column-header",
    },

    {
      title: "Address",
      dataIndex: "address",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Note",
      dataIndex: "note",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      className: "action-column-header",
    },

    // {
    //   title: "Verified",
    //   dataIndex: "verified",
    //   align: "center",
    //   className: "action-column-header",
    // },
  ];

  const data = users?.map((item, index) => {
    return {
      key: index,
      firstName: `${item?.acceptedBy?.firstName} ${item?.acceptedBy?.lastName}`,
      createdBy: `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`,
      email: moment(item?.createdAt).format("MM-DD-YYYY"),
      address: item?.location?.address,
      product: item?.productName,
      note: item?.note,
      status: (
        <h1
          style={{
            color:
              item?.status == "pending"
                ? "#7254B2"
                : item?.status == "accepted"
                ? "green"
                : item?.status == "cancelled"
                ? "red"
                : "purple",
          }}
        >
          {item?.status}
        </h1>
      ),
      profilePicture: (
        <div className="product-list-image">
          <Image width={50} src={item?.image} alt="profile-image" />
        </div>
      ),
      //   verified: (
      //     <div className="server-roles-trash-btn">
      //       <img src={item?.verified ? trueIcon : crossIcon} alt="" />
      //     </div>
      //   ),

      delete: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => deleteUser(item?._id)}
        >
          <img src={redTrash} alt="" />
        </div>
      ),
    };
  });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>

        <Breadcrumb.Item>Requests</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1> {`Requests`}</h1>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
        ></Table>
      </div>
    </div>
  );
};

export default Request;
